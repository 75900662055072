// Security.js

import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  Button, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Box, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  IconButton, 
  Tooltip,
  Snackbar,
  Alert
} from '@mui/material';
import { CheckCircle, Error, Info, FileCopy, Delete } from '@mui/icons-material';
import axios from 'axios';

const Security = () => {
  const [domain, setDomain] = useState('');
  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchDomains();
  }, []);

  useEffect(() => {
    console.log('Fetched domains:', domains);
  }, [domains]);

  const fetchDomains = async () => {
    try {
      const response = await axios.get('/api/domains');
      console.log('Response from /api/domains:', response.data);
      if (Array.isArray(response.data)) {
        setDomains(response.data);
      } else {
        console.error('Response data is not an array:', response.data);
        setDomains([]);
      }
    } catch (error) {
      console.error('Failed to fetch domains', error);
      setErrorMessage('Failed to fetch domains.');
    }
  };

  const handleAddDomain = async () => {
    try {
      if (!domain) {
        setErrorMessage('Please enter a domain.');
        return;
      }
      const response = await axios.post('/api/domains', { domain });
      setDomains([...domains, response.data]);
      setDomain('');
      setSuccessMessage('Domain added successfully.');
    } catch (error) {
      console.error('Failed to add domain', error);
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(`Error: ${error.response.data.error}`);
      } else {
        setErrorMessage('Failed to add domain. Please try again later.');
      }
    }
  };

  const handleOpenDialog = (d) => {
    setSelectedDomain(d);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedDomain(null);
  };

  const handleOpenConfirmDialog = (d) => {
    setSelectedDomain(d);
    setConfirmOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmOpen(false);
    setSelectedDomain(null);
  };

  const handleDeleteDomain = async () => {
    try {
      await axios.delete(`/api/domains/${selectedDomain._id}`);
      setDomains(domains.filter(d => d._id !== selectedDomain._id));
      setConfirmOpen(false);
      setSelectedDomain(null);
      setSuccessMessage('Domain deleted successfully.');
    } catch (error) {
      console.error('Failed to delete domain', error);
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(`Error: ${error.response.data.error}`);
      } else {
        setErrorMessage('Failed to delete domain. Please try again later.');
      }
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setSuccessMessage('Copied to clipboard!');
    }, (err) => {
      console.error('Failed to copy: ', err);
      setErrorMessage('Failed to copy text.');
    });
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Security Settings</Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField 
          label="Domain" 
          value={domain} 
          onChange={(e) => setDomain(e.target.value)} 
          variant="outlined"
          style={{ marginRight: '10px' }}
        />
        <Button variant="contained" color="primary" onClick={handleAddDomain}>Add Domain</Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Domain</TableCell>
              <TableCell>SPF Record</TableCell>
              <TableCell>SPF Status</TableCell>
              <TableCell>DKIM Host</TableCell>
              <TableCell>DKIM Record</TableCell>
              <TableCell>DKIM Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(domains) && domains.length > 0 ? (
              domains.map((d) => (
                <TableRow key={d._id}>
                  <TableCell>{d.domain}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center" style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
                      {d.spfRecord || 'N/A'}
                      {d.spfRecord && (
                        <Tooltip title="Copy SPF Record">
                          <IconButton onClick={() => copyToClipboard(d.spfRecord)} size="small">
                            <FileCopy />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {d.spfStatus ? <CheckCircle color="primary" /> : <Error color="error" />}
                  </TableCell>
                  <TableCell>{d.dkimHost || 'N/A'}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center" style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
                      {d.dkimRecord || 'N/A'}
                      {d.dkimRecord && (
                        <Tooltip title="Copy DKIM Record">
                          <IconButton onClick={() => copyToClipboard(d.dkimRecord)} size="small">
                            <FileCopy />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {d.dkimStatus ? <CheckCircle color="primary" /> : <Error color="error" />}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="View Details">
                      <IconButton onClick={() => handleOpenDialog(d)} style={{ marginLeft: '10px' }}>
                        <Info />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Domain">
                      <IconButton onClick={() => handleOpenConfirmDialog(d)} style={{ marginLeft: '10px' }}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No domains found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedDomain && (
        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle>Domain Details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <strong>Domain:</strong> {selectedDomain.domain}<br />
              <strong>SPF Record:</strong> {selectedDomain.spfRecord || 'N/A'}<br />
              <strong>SPF Status:</strong> {selectedDomain.spfStatus ? 'Valid' : 'Invalid'}<br />
              <strong>DKIM Host:</strong> {selectedDomain.dkimHost || 'N/A'}<br />
              <strong>DKIM Record:</strong> {selectedDomain.dkimRecord || 'N/A'}<br />
              <strong>DKIM Status:</strong> {selectedDomain.dkimStatus ? 'Valid' : 'Invalid'}
            </DialogContentText>
            <Typography variant="body1" gutterBottom>
              <strong>SPF Setup Instructions:</strong><br />
              {selectedDomain.spfRecord ? (
                <div>
                  Add the following SPF record to your DNS settings:<br />
                  <code>{selectedDomain.spfRecord}</code>
                </div>
              ) : 'SPF record not generated yet.'}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>DKIM Setup Instructions:</strong><br />
              {selectedDomain.dkimRecord ? (
                <div>
                  Add the following DKIM record to your DNS settings:<br />
                  <code>{selectedDomain.dkimRecord}</code>
                </div>
              ) : 'DKIM record not generated yet.'
              }
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">Close</Button>
          </DialogActions>
        </Dialog>
      )}

      <Dialog
        open={confirmOpen}
        onClose={handleCloseConfirmDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the domain <strong>{selectedDomain?.domain}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">Cancel</Button>
          <Button onClick={handleDeleteDomain} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar ??? ??????????? ?? ?????? */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Snackbar ??? ??????????? ?? ??????? */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Security;
